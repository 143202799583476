import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";



const PageNotFound = () => {


  return (
    <div>
      <Layout>
        <Helmet>
          <title>Page Not Found | The WorldGrad</title>
          <meta
            name="description"
            content="We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's behalf."
          />
          <meta
            name="keywords"
            content="study abroad, study overseas, online education, online education, the worldgrad"
          />
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </Helmet>


        <div className="bg-white">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6 my-5">
                <div className="card p-5 border-0 shadow">
                  <div className="card-body">
                    <h1 className="display-1 mb-3 text-warning">404</h1>
                    <h3>We can't find that page</h3>
                    <p className="mb-4">
                      We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's behalf.
                    </p>
                    <a href="/" className="btn btn-warning">
                      Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default PageNotFound;
